{
    "META_HOME": {
        "TITLE": "Humo - Logicalsounds",
        "VIEWPORT": "width=device-width, initial-scale=1.0",
        "AUTHOR": "Humo",
        "APPLICATION-NAME": "humomusic",
        "DESCRIPTION": "Humo Music's official website. Sounds from, with and for the soul",
        "GENERATOR": "code",
        "KEYWORDS": "humo, humo music, humomusic, humomusic.com, music",
        "ABSTRACT": "Humo Music's official website. Sounds from, with and for the soul",
        "CONTACT": "@humo432",
        "COPYRIGHT": "Logicalsounds Records",
        "DISTRIBUTION": "global",
        "LANGUAGE": "English",
        "WEB_AUTHOR": "humomusic.com",
        "ROBOTS": "index, follow"
    },
    "META_ABOUT": {
        "TITLE": "Humo - About",
        "VIEWPORT": "width=device-width, initial-scale=1.0",
        "AUTHOR": "Humo",
        "APPLICATION-NAME": "humomusic",
        "DESCRIPTION": "Learn more about Humo Music. Motivations, trajectory, achievements, their music and frequencies",
        "GENERATOR": "code",
        "KEYWORDS": "",
        "ABSTRACT": "Learn more about Humo Music. Motivations, trajectory, achievements, their music and frequencies",
        "CONTACT": "@humo432",
        "COPYRIGHT": "Logicalsounds Records",
        "DISTRIBUTION": "global",
        "LANGUAGE": "English",
        "WEB_AUTHOR": "humomusic.com",
        "ROBOTS": "index, follow"
    },
    "META_RELEASES": {
        "TITLE": "Humo - Releases",
        "VIEWPORT": "width=device-width, initial-scale=1.0",
        "AUTHOR": "Humo",
        "APPLICATION-NAME": "humomusic",
        "DESCRIPTION": "Discover their music and learn about the latest news. Explore their latest releases",
        "GENERATOR": "code",
        "KEYWORDS": "",
        "ABSTRACT": "Discover their music and learn about the latest news. Explore their latest releases",
        "CONTACT": "@humo432",
        "COPYRIGHT": "Logicalsounds Records",
        "DISTRIBUTION": "global",
        "LANGUAGE": "English",
        "WEB_AUTHOR": "humomusic.com",
        "ROBOTS": "index, follow"
    },
    "META_CONTACT": {
        "TITLE": "Humo - Contact",
        "VIEWPORT": "width=device-width, initial-scale=1.0",
        "AUTHOR": "Humo",
        "APPLICATION-NAME": "humomusic",
        "DESCRIPTION": "Find me on social media",
        "GENERATOR": "code",
        "KEYWORDS": "",
        "ABSTRACT": "Find me on social media",
        "CONTACT": "@humo432",
        "COPYRIGHT": "Logicalsounds Records",
        "DISTRIBUTION": "global",
        "LANGUAGE": "English",
        "WEB_AUTHOR": "humomusic.com",
        "ROBOTS": "index, follow"
    },
    "HOME": {
        "TITLE": "Humo - Logicalsounds",
        "BANNER": {
            "SLOGAN": "Playing with sounds"
        }
    },
    "LATESTS_RELEASES": "Latests releases",
    "SIDENAV": {
        "HOME": {
            "TITLE": "Home"
        },
        "ABOUT": {
            "TITLE": "About"
        },
        "RELEASES": {
            "TITLE": "Releases"
        },
        "CONTACT": {
            "TITLE": "Contact"
        }
    },
    "PLAYER": {
        "LOADING": "Loading...",
        "PLAY": "Play",
        "PAUSE": "Pause",
        "NEXT": "Next",
        "PREV": "Prev",
        "DATE": "Release date",
        "ALBUM": "Album",
        "SOON": "Coming soon...",
        "SPOTIFY": "Get it in Spotify",
        "BEATPORT": "Get it in Beatport",
        "AMAZON": "Get it Amazon",
        "APPLE": "Get it Apple Music"
    },
    "CONTACT": {
        "X": "Follow Humo432 on X",
        "INSTAGRAM": "Follow humo_music on Instagram",
        "FOLLOW": "follow me :)"
    }
}