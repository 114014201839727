import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private languageSubject: BehaviorSubject<string> = new BehaviorSubject('');
    public languageObs: Observable<string> = this.languageSubject.asObservable();

    constructor() {

    }
    public setLanguageSubject(language: string) {
        this.languageSubject.next(language);
    }


}