import { TranslateLoader } from '@ngx-translate/core';
import { of , Observable } from 'rxjs';

import * as contentEs from './src/assets/i18n/es.json';
import * as contentEn from './src/assets/i18n/en.json';

const TRANSLATIONS = {
    'es': contentEs,
    'en': contentEn
}
type translations = {
  es: 'es',
  en: 'en'
}
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: keyof translations): Observable<any> {
    return of(TRANSLATIONS[lang]);
  }
}