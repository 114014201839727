{
    "META_HOME": {
        "TITLE": "Humo - Logicalsounds",
        "VIEWPORT": "width=device-width, initial-scale=1.0",
        "AUTHOR": "Humo",
        "APPLICATION-NAME": "humomusic",
        "DESCRIPTION": "Web oficial de Humo Music. Sonidos desde, con y para el alma",
        "GENERATOR": "code",
        "KEYWORDS": "humo, humo music, humomusic, humomusic.com, music",
        "ABSTRACT": "Web oficial de Humo Music. Sonidos desde, con y para el alma",
        "CONTACT": "@humo432",
        "COPYRIGHT": "Logicalsounds Records",
        "DISTRIBUTION": "global",
        "LANGUAGE": "Spanish",
        "WEB_AUTHOR": "humomusic.com",
        "ROBOTS": "index, follow"
    },
    "META_ABOUT": {
        "TITLE": "Humo - Acerca",
        "VIEWPORT": "width=device-width, initial-scale=1.0",
        "AUTHOR": "Humo",
        "APPLICATION-NAME": "humomusic",
        "DESCRIPTION": "Conoce más sobre Humo Music. Motivaciones, trayectoria, logros, su música y frecuencias",
        "GENERATOR": "code",
        "KEYWORDS": "",
        "ABSTRACT": "Conoce más sobre Humo Music. Motivaciones, trayectoria, logros, su música y frecuencias",
        "CONTACT": "@humo432",
        "COPYRIGHT": "Logicalsounds Records",
        "DISTRIBUTION": "global",
        "LANGUAGE": "Spanish",
        "WEB_AUTHOR": "humomusic.com",
        "ROBOTS": "index, follow"
    },
    "META_RELEASES": {
        "TITLE": "Humo - Lanzamientos",
        "VIEWPORT": "width=device-width, initial-scale=1.0",
        "AUTHOR": "Humo",
        "APPLICATION-NAME": "humomusic",
        "DESCRIPTION": "Descubre sus música y conoce las últimas novedades. Explora sus últimos lanzamientos",
        "GENERATOR": "code",
        "KEYWORDS": "",
        "ABSTRACT": "Descubre sus música y conoce las últimas novedades. Explora sus últimos lanzamientos",
        "CONTACT": "@humo432",
        "COPYRIGHT": "Logicalsounds Records",
        "DISTRIBUTION": "global",
        "LANGUAGE": "Spanish",
        "WEB_AUTHOR": "humomusic.com",
        "ROBOTS": "index, follow"
    },
    "META_CONTACT": {
        "TITLE": "Humo - Contacto",
        "VIEWPORT": "width=device-width, initial-scale=1.0",
        "AUTHOR": "Humo",
        "APPLICATION-NAME": "humomusic",
        "DESCRIPTION": "Encuentrame en las redes sociales",
        "GENERATOR": "code",
        "KEYWORDS": "",
        "ABSTRACT": "Encuentrame en las redes sociales",
        "CONTACT": "@humo432",
        "COPYRIGHT": "Logicalsounds Records",
        "DISTRIBUTION": "global",
        "LANGUAGE": "Spanish",
        "WEB_AUTHOR": "humomusic.com",
        "ROBOTS": "index, follow"
    },
    "HOME": {
        "TITLE": "Humo - Logicalsounds",
        "BANNER": {
            "SLOGAN": "Jugando con sonidos"
        }
    },
    "LATESTS_RELEASES": "Últimos lanzamientos",
    "SIDENAV": {
        "HOME": {
            "TITLE": "Inicio"
        },
        "ABOUT": {
            "TITLE": "Acerca"
        },
        "RELEASES": {
            "TITLE": "Lanzamientos"
        },
        "CONTACT": {
            "TITLE": "Contacto"
        }
    },
    "PLAYER": {
        "LOADING": "Cargando...",
        "PLAY": "Reproducir",
        "PAUSE": "Pausar",
        "NEXT": "Siguiente",
        "PREV": "Anterior",
        "DATE": "Fecha de lanzamiento",
        "ALBUM": "Album",
        "SOON": "Próximamente...",
        "SPOTIFY": "Consíguelo en Spotify",
        "BEATPORT": "Consíguelo en Beatport",
        "AMAZON": "Consíguelo en Amazon",
        "APPLE": "Consíguelo en Apple Music"
    },
    "CONTACT": {
        "X": "Sigue a Humo432 en X",
        "INSTAGRAM": "Sigue a humo_music en Instagram",
        "FOLLOW": "sígueme :)"
    }
}